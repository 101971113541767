<template>
  <span>
    <b-button
      v-if="hasMore"
      variant="link"
      class="p-0"
      :disabled="isLoading"
      @click="loadNextPage"
    >
      {{ $t("action.load-more") }}... <b-spinner v-if="isLoading" />
    </b-button>
    <places-map
      :locations="locations"
      :general-view="false"
    />
  </span>
</template>

<script>
import { BButton, BSpinner, BOverlay } from "bootstrap-vue";
import Service from "@/config/service-identifiers";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import PlacesMap from "@core/components/places-map-input/PlacesMap.vue";
import { TypeMorph } from "@copernicsw/community-common";
import {
  ENABLED_APPS_GETTERS,
} from "@/store/enabled-apps/enabled-apps-store-constants";
const RequestParamMap = Object.freeze({
  [TypeMorph.Collective]: "communityKey",
  [TypeMorph.Event]: "eventKey",
});

export default {
  name: "LocationsMap",
  components: {
    BButton,
    BSpinner,
    BOverlay,
    PlacesMap,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
    all: Boolean,
    isWidget: Boolean,
  },
  data() {
    return {
      isLoading: true,
      locations: [],
      total: 0,
      currentPage: 0,
      itemKey: "",
      type: "collective",
    };
  },
  computed: {
    currentCollectiveKey() {
      this.itemKey = this.$store.getters.currentCollective.key;
      return this.$store.getters.currentCollective.key;
    },
    hasMore() {
      return this.locations.length < this.total;
    },
    mapApp() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].map;
    },
    isNew() {
      return new Date(this.collective.createdAt) >= new Date('Wed Jan 11 2023 15:02:21 GMT+0100 (hora estándar de Europa central)');
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
  },
  async created() {
    this.updateBreadcrumbs();
    this.loadNextPage();
  },
  methods: {
    updateBreadcrumbs() {
      this.$store.commit("app/SET_BREADCRUMBS", null);
    },
    async loadNextPage() {
      let modelType = '';
      let getAll = false;
      if (this.mapApp?.customization?.displayOptions === 0) {
        modelType = 'community'
      } else if (this.mapApp?.customization?.displayOptions === 1) {
        modelType = 'organization'
      } else if (this.mapApp?.customization?.displayOptions === 2) {
        modelType = 'event'
      } else if (this.mapApp?.customization?.displayOptions === 3) {
        getAll = true;
      } else if (this.mapApp?.customization?.displayOptions === 4) {
        modelType = 'member'
      } else if (this.mapApp?.customization?.displayOptions === 5) {
        modelType = 'society'
      } else if (this.mapApp?.customization?.displayOptions === 6) {
        modelType = 'room'
      } else if (this.mapApp?.customization?.displayOptions === 7) {
        modelType = 'service'
      } else if (this.mapApp?.customization?.displayOptions === 8) {
        modelType = 'contact'
      } else if (this.mapApp?.customization?.displayOptions === 9) {
        modelType = 'challenge'
      } else if (this.isNew) {
        getAll = true
      } else {
        modelType = 'community'
      }

      this.itemKey = this.$store.getters.currentCollective.key;
      this.isLoading = true;
      try {
        const response = await this.$store.$service[Service.BackendClient].get(
          "locations",
          {
            params: {
              [RequestParamMap[this.type]]: this.itemKey,
              page: this.currentPage + 1,
              count: 20,
              ...(getAll ? { getAll: true } : ''),
              ...(modelType !== '' ? { modelType } : ''),
            },
          },
        );
        this.currentPage += 1;
        const allLocations = response.data.data;
        this.locations.push(...allLocations);
        this.total = this.locations.length;
      } catch{
        this.notifyError(this.$t("locations.message.fetch-error"));
      }
      this.isLoading = false;
    },
  },
};
</script>
