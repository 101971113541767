import moment from "moment";
//import 'moment-timezone';

export function getEventOwnedBy(event) {
  if (!event) {
    return null;
  }
  const ownedBy = event.ownedByCommunity || event.ownedByUser || {};

  return {
    ...ownedBy,
    name:
      ownedBy.name && ownedBy.surname
        ? `${ownedBy.name} ${ownedBy.surname}`
        : ownedBy.name,
    logoURL: ownedBy.logoURL || ownedBy.avatarURL,
  };
}

export function getEventDatesRange(startDate, endDate, allDay, locale) {
  if (!endDate && !startDate) {
    return "";
  }

  const startMoment = moment(startDate).locale(locale);
  const endMoment = moment(endDate).locale(locale);

  if (!allDay) {
    if (endDate) {
      if (startMoment.isSame(endMoment, "day")) {
        // If it's a single day event. Example: Wednesday, May 12 ⋅ 10:00 – 10:30am
        return `${startMoment.format(
          "dddd D, MMM YYYY ⋅ HH:mm"
        )}h – ${endMoment.format("HH:mm")}h`;
      }
      if (startMoment.isSame(endMoment, "month")) {
        return `${startMoment.format(
          "dddd D, MMM ⋅ HH:mm"
        )}h – ${endMoment.format("dddd D, MMM YYYY ⋅ HH:mm")}h`;
      }

      if (startMoment.isSame(endMoment, "year")) {
        return (
          `${startMoment.format("D MMM ⋅ h:mma")} – ` +
          `${endMoment.format("D MMM ⋅ h:mma, YYYY")}`
        );
      }

      return (
        `${startMoment.format("D MMM ⋅ h:mma, YYYY")} - ` +
        `${endMoment.format("D MMM ⋅ h:mma, YYYY")}`
      );
    }

    // doesn't have end date
    return startMoment.format("dddd D, MMM, YYYY ⋅ h:mma");
  }

  // is allDay event
  return startMoment.format("dddd D, MMM, YYYY");
}

/*export function getEventDatesRangeTZ(startDate, endDate, allDay, locale, timezone) {
  if (!endDate && !startDate) {
    return "";
  }

  const startMoment = moment(startDate).tz(timezone);
  const endMoment = moment(endDate).tz(timezone);

  if (!allDay) {
    if (endDate) {
      if (startMoment.isSame(endMoment, "day")) {
        // If it's a single day event. Example: Wednesday, May 12 ⋅ 10:00 – 10:30am
        return `${startMoment.format(
          "dddd D, MMM YYYY ⋅ HH:mm"
        )}h – ${endMoment.format("HH:mm")}h`;
      }
      if (startMoment.isSame(endMoment, "month")) {
        return `${startMoment.format(
          "dddd D, MMM ⋅ HH:mm"
        )}h – ${endMoment.format("dddd D, MMM YYYY ⋅ HH:mm")}h`;
      }

      if (startMoment.isSame(endMoment, "year")) {
        return (
          `${startMoment.format("D MMM ⋅ h:mma")} – ` +
          `${endMoment.format("D MMM ⋅ h:mma, YYYY")}`
        );
      }

      return (
        `${startMoment.format("D MMM ⋅ h:mma, YYYY")} - ` +
        `${endMoment.format("D MMM ⋅ h:mma, YYYY")}`
      );
    }

    // doesn't have end date
    return startMoment.format("dddd D, MMM, YYYY ⋅ h:mma");
  }

  // is allDay event
  return startMoment.format("dddd D, MMM, YYYY");
}*/