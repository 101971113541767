import moment from 'moment';

function serialize(obj) {
  return Object.entries(obj)
    .map(([prop, value]) => `${encodeURIComponent(prop)}=${encodeURIComponent(value)}`).join('&');
}

/**
 * Source was https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs
 */
export default {
  outlook({
    title,
    startDate,
    endDate,
    allDay,
    description,
    location,
    guests,
  }) {
    const startDateMoment = moment(startDate);
    let startdt = '';
    let enddt = '';
    if (allDay) {
      startdt = `${startDateMoment.format('YYYY-MM-DD')}Z`;
      enddt = `${startDateMoment.clone().add(1, 'day').format('YYYY-MM-DD')}Z`;
    } else {
      if (!endDate) {
        throw new Error('Either allDay or endDate are mandatory');
      }
      startdt = `${startDateMoment.format('YYYY-MM-DDTHH:mm:ss')}Z`;
      enddt = `${moment(endDate).format('YYYY-MM-DDTHH:mm:ss')}Z`;
    }

    const guestsEmails = (guests || []).map(({ email }) => email).filter((email) => !email);

    const outlookArgs = {
      path: '/calendar/action/compose',
      rru: 'addevent',
      startdt,
      enddt,
      subject: title,
      allday: allDay,
      body: description,
      location,
      to: guestsEmails.length ? guestsEmails : '',
    };

    return `https://outlook.live.com/calendar/0/deeplink/compose?${serialize(outlookArgs)}`;
  },
  google({
    eventURL,
    title,
    startDate,
    endDate,
    allDay,
    description,
    location,
    guests, // List of guests users
    timezone,
  }) {
    const startDateMoment = moment(startDate);
    let dates = '';
    if (allDay) {
      dates = `${startDateMoment.format('YYYYMMDD')}/${startDateMoment.clone().add(1, 'day').format('YYYYMMDD')}`;
    } else {
      if (!endDate) {
        throw new Error('Either allDay or endDate are mandatory');
      }
      dates = `${startDateMoment.format('YYYYMMDDTHHmmss')}Z/${moment(endDate).format('YYYYMMDDTHHmmss')}Z`;
    }

    const guestsEmails = (guests || []).map(({ email }) => email).filter((email) => !email);

    const googleArgs = {
      text: title,
      dates,
      details: description,
      location: location || '',
      sprop: `website:${eventURL}`,
      add: guestsEmails.length ? guestsEmails : '',
      ctz: timezone || '',
    };

    return `https://www.google.com/calendar/render?action=TEMPLATE&${serialize(googleArgs)}`;
  },
  iCalendar({
    eventURL,
    title,
    startDate,
    endDate,
    allDay,
    description,
    location,
  }) {
    const startDateMoment = moment(startDate);
    const startdt = `${startDateMoment}`;
    let enddt = '';
    if (allDay) {
      enddt = `${startDateMoment.clone().add(1, 'day')}`;
    } else {
      if (!endDate) {
        throw new Error('Either allDay or endDate are mandatory');
      }
    }

    enddt = `${moment(endDate).format('YYYYMMDDTHHmmss')}Z`;
    
    description = description.split('"');
    const descriptionText = description[0].replace(/<[^>]*>?/gm, '');
    const descriptionURL = description[1];

    let startToDate = new Date(startdt);

    const start = `${moment(startToDate).format('YYYYMMDDTHHmmss')}Z`;

    const end = enddt;

    let iCalUrl = encodeURI(
      `data:text/calendar;charset=utf8,${[
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${eventURL}`,
        `DTSTART:${start}`,
        `DTEND:${end}`,
        `SUMMARY:${title || ''}`,
        `DESCRIPTION:${descriptionText || ''}`,
        `URL:${descriptionURL}`,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n')}`,
    );

    // data is truncated when it contains a "#" character
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURI
    // https://en.wikipedia.org/wiki/Percent-encoding
    iCalUrl = iCalUrl.replace(/#/g, '%23');

    return iCalUrl;
  },
};
